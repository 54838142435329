import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { LogoIcon } from '../appearence/icons/logo_icon';
import { ACTION_NEED_TO_SHOW_ON_BOARDING, ACTIVE_FORM_LOGIN, ACTIVE_FORM_SIGN_UP, ERROR_WRONG_CODE_TYPE, ERROR_WRONG_PHONE_TYPE, LOCALE_EN, TABLET_WIDTH, errorCallback, makeUrl } from "../constant";
import { GET_CODE_REQUEST, LOGIN_REQUEST, SIGN_UP_REQUEST, postFetchRequest } from '../store/requests';
import { ArrowIcon } from '../appearence/icons/arrow_icon';
import { CloseIcon } from '../appearence/icons/close_icon';
import { getLang, i18n } from "../i18n";
import useWindowDimensions from '../hooks/useWindowDimension';
import CommitInfo from './sharedComponents/CommitInfo';
import PhoneInput from './sharedComponents/PhoneInput';
import { userService } from '../services/userService';

const handleGetTitle = (activeForm, codeIsSended, width) => {
    let result = '';
    if (codeIsSended && !width > TABLET_WIDTH) {
        result = i18n.get("public.app.page.login.code");
    } else {
        if (activeForm === ACTIVE_FORM_LOGIN) {
            result = i18n.get("public.app.page.login.signIn");
        } else {
            result = i18n.get("public.app.page.login.signUp");
        }
    }
    return result;
};

const durationUnitGenerator = (it) => {
    const text_forms = ['секунду', 'секунды', 'секунд'];
    const n = Math.abs(it) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];
};

export const FillCodeLayout = ({ width, setCodeIsSended, setCode, code, error, counter, handleSendCode, handleLogin }) => (
    <>
        {width <= TABLET_WIDTH ? null : <ArrowIcon nameOfClass="login__icon-back" handleClick={setCodeIsSended} value={false} />}
        <h2 className="block__content login__subtitle">{i18n.get("public.app.page.login.sendCode")}</h2>
        <label className="block__text login__label login__label_fix-width">{i18n.get("public.app.page.login.code")}
            <input className={`block__content login__input ${error.type === ERROR_WRONG_CODE_TYPE ? 'login__input_error' : ''}`} value={code}
                onChange={handleLogin} type="password" placeholder="******" />
            <CloseIcon nameOfClass={"login__icon login__icon-close"} handleClick={setCode} value={''} />
        </label>
        {error.type === ERROR_WRONG_CODE_TYPE ? <p className="block__bullet alert">{error.message}</p> : ''}
        {counter !== 0 ?
            <p className="block__text login__info navigation-link">{i18n.get("public.app.page.login.askAgain")} {' '}
                <span className={`login__info-counter ${counter > 19 ? "" : counter >= 10 ? 'login__info-counter_middle' : 'login__info-counter_short'}`}>
                    {counter} </span>
                {getLang().split('-')[0] !== LOCALE_EN ? durationUnitGenerator(counter) : "seconds"}</p> :
            <p className="block__text login__info navigation-link" onClick={() => handleSendCode(true)}>{i18n.get("public.app.page.login.sendAgain")}</p>}
        <p className="block__text login__info login__info_hover">{i18n.get("public.app.page.login.dontReceive")}</p>
    </>
);

const FillPhoneLayout = ({ phone, setPhoneInput, error, setActiveForm, activeForm, handleSendCode }) => (
    <>
        {activeForm === ACTIVE_FORM_SIGN_UP ?
            <label className="block__text login__label login__label-phone">
                <p className="login__label-text">{i18n.get("public.app.page.login.phone")}</p>
                <input className="block__content login__input" disabled="true" placeholder={i18n.get('public.app.page.login.registrationPhrase')} />
            </label>
            :
            <PhoneInput phone={phone} setPhoneInput={setPhoneInput} error={error} />}
        {error.type === ERROR_WRONG_PHONE_TYPE ? <p className="block__bullet alert">{error.message}</p> : ''}
        <button type="button" className={`block__button button login__button ${phone ? '' : 'button_disabled'}`}
            onClick={() => !phone ? null : handleSendCode()}>
            <span className="button__content">{i18n.get("public.app.page.login.continue")}</span>
        </button>
        <p className="block__content login__separator">{i18n.get("public.app.page.login.or")}</p>
        <h3 className="block__content login__text navigation-link" onClick={() => setActiveForm(activeForm === ACTIVE_FORM_LOGIN ? ACTIVE_FORM_SIGN_UP : ACTIVE_FORM_LOGIN)}>
            {activeForm !== ACTIVE_FORM_LOGIN ? i18n.get("public.app.page.login.signIn") : i18n.get("public.app.page.login.signUp")}
        </h3>
    </>
);

const LoginPage = ({ setAccessToken }) => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [activeForm, setActiveForm] = useState(ACTIVE_FORM_LOGIN);
    const [error, setError] = useState({ message: '', type: '' });
    const [codeIsSended, setCodeIsSended] = useState(false);
    const [code, setCode] = useState('');
    const [counter, setCounter] = useState(60);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setError({ message: '', type: '' });
    }, [phone]);

    const succesLoginCallback = (response) => {
        if (response.status === 200) {
            setAccessToken(true);
            userService.loadUser((result) => {
                if(result.needActions && result.needActions.includes(ACTION_NEED_TO_SHOW_ON_BOARDING)) {
                    navigate('/onboarding');
                } else {
                    navigate('/plan');
                }
            }, errorCallback);   
        }
    };

    const handleLogin = (e) => {
        setError({ message: '', type: '' });
        setCode(e.target.value);
        if (phone && e.target.value.trim().length === 6) {
            const data = { phone: '+7' + phone, code: e.target.value };
            setCode('');
            postFetchRequest(makeUrl([LOGIN_REQUEST]), data, succesLoginCallback, errorCallback, true);
        } else {
            setError({ type: ERROR_WRONG_CODE_TYPE, message: 'Введен неверный код' });
        }
    };

    const successSendCodeCallback = (response) => {
        if (response.ok) {
            setCodeIsSended(true);
        } else {
            setError({ type: ERROR_WRONG_PHONE_TYPE, message: 'Введен неверный номер телефона' });
        }
    };

    const handleSendCode = (sendCodeAgain = null) => {
        counter === 0 && setCounter(60);
        const data = {
            phone: '+7' + phone,
        };
        if (activeForm === SIGN_UP_REQUEST) {
            data.locale = getLang().split('-')[0];
        }
        postFetchRequest(makeUrl([activeForm === ACTIVE_FORM_LOGIN || sendCodeAgain ? GET_CODE_REQUEST : SIGN_UP_REQUEST]),
            data, successSendCodeCallback, errorCallback, true);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !codeIsSended) {
            handleSendCode();
        }
    };

    useEffect(() => {
        if (codeIsSended) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, codeIsSended]);

    return (
        <>
            <header className="login__header">
                <LogoIcon />
                <CommitInfo />
            </header>
            <main className={`login ${codeIsSended ? 'login_full-width' : ''}`} onKeyDown={handleKeyDown}>
                <h1 className="login__title block__title-h3">{handleGetTitle(activeForm, codeIsSended, width)}</h1>
                <form className="login__form">
                    {codeIsSended ?
                        <FillCodeLayout width={width} setCodeIsSended={setCodeIsSended} setCode={setCode} code={code} error={error}
                            counter={counter} handleSendCode={handleSendCode} handleLogin={handleLogin} />
                        :
                        <FillPhoneLayout phone={phone} setPhoneInput={setPhone} error={error} setActiveForm={setActiveForm} activeForm={activeForm}
                            handleSendCode={handleSendCode} />}
                </form>
            </main>
        </>
    );
};

export default LoginPage;
