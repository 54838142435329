import React, { useState, useEffect } from 'react';
import Days from './Days';
import { CalendarIcon } from '../appearence/icons/calendar_icon';
import { getDate, getMealName } from './helper';
import Loading from './Loading';
import { GET_USER_REQUEST, MEAL_URL_PART, getFetchRequest } from '../store/requests';
import { ONE_DAY, WEEK_DAYS_COUNT, errorCallback, makeUrl } from '../constant';
import { RepeatIcon } from '../appearence/icons/repeat_icon';
import { EllipsisIcon } from '../appearence/icons/ellipsis_icon';
import { ArrowIcon } from '../appearence/icons/arrow_icon';
import Calendar from './CalendarItem';
import { i18n } from '../i18n';
import useWindowDimensions from '../hooks/useWindowDimension';
import { registerObserver, unRegisterObserver, sendWSMessage } from './sharedComponents/WebSocketConnectionService';
import { userService } from '../services/userService';
import CookiesIcon from '../appearence/icons/cookies_icon';
import MealPlanItemIngredientsList from './meals/MealPlanItemIngredientsList';

const MEAL_EVENT_TYPE = '/meal';
const BREAKFAST_START_TIME = 5;
const AFTER_MORNING_START_TIME = 10;
const LUNCH_START_TIME = 12;
const BEFORE_EVENING_START_TIME = 15;
const DINNER_START_TIME = 17;
const NIGHT_START_TIME = 20;

const SCROLL_WEEK_VALUE = 300;
const NEED_TO_USE_ZERO_MONTH_COUNT = 9;

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const START_DAY_TIME = '00:00:00';
const END_DAY_TIME = '23:59:00';

const getDateString = (currentDate) => {
    const d = new Date(currentDate);
    const time = d.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':');
    const preparedMonth = d.getMonth() + 1;
    return `${d.getFullYear()}-${preparedMonth > NEED_TO_USE_ZERO_MONTH_COUNT ? preparedMonth : "0" + preparedMonth}-${d.getDate()} ${time}`;
};

const getTimeFromData = (data) => {
    const result = data.split(' ')[1].split(':').slice(0, 2).join(':');
    return result;
};

const getDateFromData = (data) => {
    const preparedData = getDateString(data);
    const result = preparedData.split(' ');
    return result[0];
};

const createISOString = (date, time) => {
    return new Date(date).toISOString().slice(0, 11) + time;
};

const handleCreateWeek = (startDate, endDate) => {
    const result = [];
    if (startDate && endDate) {
        const daysCount = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / ONE_DAY);
        const date = new Date(startDate).getTime();
        for (let i = 0; i <= daysCount; i++) {
            result.push(getDateString(date + i * ONE_DAY));
        }
    }

    return result;
};

const makeListOfCurrentDay = (array, currentDate) => {
    let result = {
        "breakfast": {
            code: "breakfast",
            children: [],
        },
        "after-morning": {
            code: "after-morning",
            children: [],
        },
        "lunch": {
            code: "lunch",
            children: [],
        },
        "before-evening": {
            code: "before-evening",
            children: [],
        },
        "dinner": {
            code: "dinner",
            children: [],
        },
        "night": {
            code: "night",
            children: [],
        },
    };
    for (let i = 0; i < array.length; i++) {
        if (getDateFromData(currentDate) === getDateFromData(array[i].mealTime)) {
            const time = getTimeFromData(array[i].mealTime).split(':')[0];
            if (+time < AFTER_MORNING_START_TIME && +time >= BREAKFAST_START_TIME) {
                result.breakfast.children.push(array[i]);
            } else if (+time >= AFTER_MORNING_START_TIME && +time < LUNCH_START_TIME) {
                result["after-morning"].children.push(array[i]);
            } else if (+time >= LUNCH_START_TIME && +time < BEFORE_EVENING_START_TIME) {
                result.lunch.children.push(array[i]);
            } else if (+time >= BEFORE_EVENING_START_TIME && +time < DINNER_START_TIME) {
                result["before-evening"].children.push(array[i]);
            } else if (+time >= DINNER_START_TIME && +time < NIGHT_START_TIME) {
                result.dinner.children.push(array[i]);
            } else {
                result.night.children.push(array[i]);
            }
        }
    }
    return result;
};

const findEmptyElem = (data, key) => {
    let result = true;
    if (typeof (key) === 'string') {
        data.forEach(it => getDateFromData(it.mealTime) === getDateFromData(getDateString(key)) ? result = false : null);
    } else if (typeof (key) === 'object') {
        if (key && key.children && key.children.length !== 0) {
            result = false;
        }
    }
    return result;
};

const WeekItemLayout = ({ currentPlan, checkCanScroll, handleScrollWeek, selectedDate }) => {
    try {
        const [scrollValue, setScrollValue] = useState(0);
        return (
            handleCreateWeek(selectedDate[0], selectedDate[selectedDate.length - 1]).map((date, index) => (
                findEmptyElem(currentPlan, date) ?
                    null
                    :
                    <li key={date} className="plan__week">
                        <h3 className="plan__week-title">{new Date(date).toLocaleDateString(i18n.APP_LOCALE, { weekday: 'long' })}</h3>
                        <ul className="plan__week-list" id={index}>
                            {Object.values(makeListOfCurrentDay(currentPlan, date)).map(it => (
                                <React.Fragment key={it.code + 'week' + index}>
                                    {findEmptyElem(makeListOfCurrentDay(currentPlan, date), it) ?
                                        null
                                        :
                                        <li className="plan__week-item">
                                            <RepeatIcon nameOfClass="plan__item-icon" />
                                            <h3 className="block__content plan__week-time">{i18n.get(`meal.scheme.mealTime.${it.code}`)}</h3>
                                            {it.children && it.children.length ?
                                                it.children.sort(sortByMealTime).map(dish => (
                                                    <React.Fragment key={dish.id}>
                                                        <h4 className="block__text plan__week-name">{i18n.get(`meal.scheme.mealType.${dish.mealType}`)}</h4>
                                                        {dish.ingredients && dish.ingredients.length ?
                                                            <MealPlanItemIngredientsList meal={dish} />
                                                            :
                                                            null}
                                                    </React.Fragment>
                                                ))
                                                :
                                                null}
                                        </li>}
                                </React.Fragment>
                            ))}
                        </ul>
                        <ArrowIcon handleClick={handleScrollWeek} value={[index, -1, setScrollValue]}
                            nameOfClass={`plan__week-scroll ${!checkCanScroll(index, true, scrollValue) ? "plan__week-scroll_disabled" : ""}`} />
                        <ArrowIcon handleClick={handleScrollWeek} value={[index, 1, setScrollValue]}
                            nameOfClass={`plan__week-scroll plan__week-scroll_right ${!checkCanScroll(index, false, scrollValue) ? "plan__week-scroll_disabled" : ""}`} />
                    </li>
            ))
        );
    } catch (err) {
        return null;
    }
};

const DayItemLayout = ({ currentPlan }) => {
    try {
        return (
            currentPlan.map((it) => (
                <li key={it.id} className="plan__item">
                    <RepeatIcon nameOfClass="plan__item-icon" />
                    <h3 className="main__subtitle plan__time">{getTimeFromData(it.mealTime)}</h3>
                    <h4 className="main__subtitle plan__name">{i18n.get(`meal.scheme.mealType.${it.mealType}`)}</h4>
                    {it.ingredients && it.ingredients.length ?
                        <MealPlanItemIngredientsList meal={it} />
                        :
                        null}
                </li>
            ))
        );
    } catch (err) {
        return null;
    }
};

const sortByMealTime = (a, b) => new Date(a.mealTime) - new Date(b.mealTime);

const PlanPage = () => {
    const [selectedDate, setSelectedDate] = useState([getDate(Date.now())]);
    const [currentPlan, setCurrentPlan] = useState([]);
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date(Date.now()).getMonth());
    const [loading, setLoading] = useState(false);
    const { width } = useWindowDimensions();
    const [planLoadingIsReady, setPlanLoadingIsReady] = useState(false);

    const handleCreatePlan = (e) => {
        e.stopPropagation();
        setLoading(true);
        const preparedBody = {
            startDate: createISOString(selectedDate[0], START_DAY_TIME),
            endDate: createISOString(selectedDate[selectedDate.length - 1], END_DAY_TIME),
        };
        sendWSMessage(MEAL_EVENT_TYPE, preparedBody);
    };

    const getWebSocketMessage = (message) => {
        try {
            const data = JSON.parse(message);
            if (data.event === MEAL_EVENT_TYPE) {
                setCurrentPlan(prev => [...prev, data.body].sort(sortByMealTime));
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        registerObserver(getWebSocketMessage);
        return () => {
            unRegisterObserver(getWebSocketMessage);
        };
    }, []);

    useEffect(() => {
        setPlanLoadingIsReady(false);
        setCurrentPlan([]);
        setLoading(false);
        let preparedParams = '';
        if (selectedDate.length) {
            preparedParams += `?startDate=${createISOString(selectedDate[0], START_DAY_TIME)}`;
            preparedParams += `&endDate=${createISOString(selectedDate[selectedDate.length - 1], END_DAY_TIME)}`;
            getFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, MEAL_URL_PART, preparedParams]),
                (response) => {
                    setCurrentPlan(response.sort(sortByMealTime));
                    setPlanLoadingIsReady(true);
                }, errorCallback);
        }
    }, [selectedDate]);

    const handleScrollWeek = (args) => {
        const element = document.getElementById(args[0]);
        element.scrollTo({
            top: 0,
            left: element.scrollLeft + args[1] * SCROLL_WEEK_VALUE,
            behavior: 'smooth',
        });
        args[2](element.scrollLeft + args[1] * SCROLL_WEEK_VALUE);
    };

    const checkCanScroll = (index, isLeft = false) => {
        let result = true;
        const element = document.getElementById(index);
        if (element) {
            result = isLeft ? element.scrollLeft !== 0 : element.scrollLeft !== element.scrollWidth - element.offsetWidth;
        }
        return result;
    };

    const handleCloseOpenedItems = (e) => {
        e.stopPropagation();
        setCalendarIsOpen(false);
    };

    const changeSelectedDate = (dateValue, fromDays) => {
        if (fromDays) {
            setSelectedDate([getDate(dateValue)]);
            return;
        }
        if (selectedDate.includes(getDate(dateValue))) {
            selectedDate.length > 1 ?
                setSelectedDate(selectedDate.filter(it => it !== getDate(dateValue)).sort())
                :
                setSelectedDate([getDate(dateValue)]);
        } else {
            setSelectedDate(prev => [...prev, getDate(dateValue)].sort());
        }
    };

    return (
        <section className="workspace workspace__plan" onClick={handleCloseOpenedItems}>
            <header className='plan__header'>
                <h1 className="block__title-h2 plan__title">{i18n.get("app.page.plan.title")}</h1>
                <div className='plan__header-month'>
                    <p className="block__subtitle day__month">{i18n.get(`days.scheme.months.${MONTHS[currentMonth]}`)}</p>
                    <CalendarIcon nameOfClass="plan__calendar-icon"
                        handleClick={setCalendarIsOpen} value={!calendarIsOpen} />
                    {calendarIsOpen ?
                        <div className="calendar" onClick={(e) => e.stopPropagation()}>
                            <Calendar selectedDate={selectedDate} setSelectedDate={changeSelectedDate} />
                        </div>
                        :
                        ''}
                </div>
            </header>
            <div className="plan__calendar">
                <Days selectedDate={selectedDate} changeSelectedDate={changeSelectedDate} width={width} setCurrentMonth={setCurrentMonth} />
            </div>
            <div className="plan__block">
                <article className="plan__article">
                    {planLoadingIsReady ?
                        currentPlan.length ?
                            <>
                                <EllipsisIcon nameOfClass="plan__icon" handleClick={handleCreatePlan} value={null} />
                                <ul className="plan__list">
                                    {selectedDate.length > WEEK_DAYS_COUNT ?
                                        <WeekItemLayout currentPlan={currentPlan} checkCanScroll={checkCanScroll} handleScrollWeek={handleScrollWeek} selectedDate={selectedDate} />
                                        :
                                        <DayItemLayout currentPlan={currentPlan} />}
                                </ul>
                            </>
                            :
                            <div className="plan__empty">
                                <CookiesIcon />
                                {loading ?
                                    <h2 className="block__content plan__empty-subtitle">
                                        {i18n.get("app.page.plan.create")} {selectedDate.length > WEEK_DAYS_COUNT ? i18n.get("app.page.plan.forWeek") : i18n.get("app.page.plan.toEat")} {i18n.get(`preference.scheme.when.${getMealName(selectedDate[0]).id}`)}</h2>
                                    :
                                    null}
                                <p className="block__content plan__empty-text">{loading ? i18n.get("app.page.plan.generatePlan") : i18n.get("app.page.plan.emptyPlan")}</p>
                                {loading ?
                                    <Loading />
                                    :
                                    <>
                                        <p className="block__content plan__empty-text">{i18n.get("app.page.plan.create")} {selectedDate.length > WEEK_DAYS_COUNT ? i18n.get("app.page.plan.forWeek") : i18n.get("app.page.plan.toEat")} {i18n.get(`preference.scheme.when.${(getMealName(selectedDate[0])).id}`)}</p>
                                        <button className="block__button button plan__empty-button" onClick={handleCreatePlan}>{i18n.get("app.page.plan.createPlan")}</button>
                                    </>}
                            </div>
                        :
                        null}
                </article>
                <article className="plan__article"></article>
            </div>
        </section>
    );
};

export default PlanPage;
