import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimension';
import { CloseIcon } from '../appearence/icons/close_icon';
import { RepeatIcon } from '../appearence/icons/repeat_icon';
import { PenIcon } from '../appearence/icons/pen_icon';
import { MOBILE_WIDTH, TABLET_WIDTH, errorCallback, makeUrl, ACTION_NEED_TO_SHOW_ON_BOARDING, successCallbackEmpty } from '../constant';
import { GET_CURRENT_USER_REQUEST, GET_USER_REQUEST, getFetchRequest, postFetchRequest } from '../store/requests';
import { HomeIcon } from '../appearence/icons/home_icon';
import { MugIcon } from '../appearence/icons/mug_icon';
import { i18n } from '../i18n';
import AnimationLogo from './AnimationLogo';

const MenuItemLayout1 = () => (
    <li className="menu__item navigation-link">
        <p className="menu__item-link">
            <HomeIcon nameOfClass={'menu__icon menu__icon_active'}/>
            <h2 className={`block__button menu__title menu__title_active`}>{i18n.get("app.page.plan.title")}</h2>
        </p>
    </li>
);

const MenuItemLayout2 = () => (
    <li className="menu__item navigation-link">
        <p className="menu__item-link">
            <MugIcon nameOfClass={'menu__icon menu__icon_active'}/>
            <h2 className={`block__button menu__title menu__title_active`}>{i18n.get("app.page.preference.title")}</h2>
        </p>
    </li>
);

const ONBOARDING_CONTENT = [
    {
        subtitle: 'myPlan',
        buttons: [
            {
                content: 'continue',
            },
            {
                content: 'continue',
            },
        ],
    },
    {
        subtitle: 'preference',
        buttons: [
            {
                content: 'back',
            },
            {
                content: 'finish',
            },
        ],
    },
];

const getCurrentWidthImage = (width, currentSlide) => {
    let result = '';
    if(width < MOBILE_WIDTH) {
        result = `mobile_${currentSlide}.png`;
    } else if(width < TABLET_WIDTH) {
        result = `tablet_${currentSlide}.png`;
    } else {
        result = `desktop_${currentSlide}.png`;
    }
    return result;
};

const Onboarding = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [userId, setUserId] = useState('');
    const [animationIsStarted, setAnimationIsStarted] = useState(true);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    let timeout;

    useEffect(()=> {
        getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (response) => setUserId(response.id), errorCallback);
        timeout = setTimeout(() => setAnimationIsStarted(false), 1000);
        return () => clearTimeout(timeout);
    },[]);

    const handleCloseUnboarding = () => {
        const data = {
            action: ACTION_NEED_TO_SHOW_ON_BOARDING,
        };
        postFetchRequest(makeUrl([GET_USER_REQUEST, userId, '/action/']), data, successCallbackEmpty, errorCallback);
        navigate('/initial-preferences');
    };

    return (
        <section className="workspace">
            <div className={`onboarding__wrapper ${animationIsStarted ? 'onboarding__wrapper_animation' : ''}`}></div>
            {animationIsStarted ? 
                <AnimationLogo />
                :
                <>
                    {width > TABLET_WIDTH ? 
                        <div className={`onboarding__menu_${currentSlide}`} onClick={(e) => e.preventDefault()}>
                            {currentSlide === 1 ? 
                                <MenuItemLayout1 />
                                :
                                <MenuItemLayout2 />
                            }
                        </div> 
                        : 
                        null}
                    <article className="onboarding">
                        <h1 className="main__title onboarding__title">
                            {i18n.get('app.page.onboarding.title')}{width < MOBILE_WIDTH ? <br/> : null} {i18n.get('app.page.onboarding.to')} Chapche!
                        </h1>
                        <img className={`onboarding__img onboarding__img_${currentSlide}`} 
                            src={`/static/images/${getCurrentWidthImage(width, currentSlide)}`} alt="screenshot of our app" />
                        <div className={`onboarding__info onboarding__info_${currentSlide}`}>
                            <h2 className="block__title-h3 onboarding__subtitle">{i18n.get(`app.page.onboarding.${ONBOARDING_CONTENT[currentSlide - 1].subtitle}`)}</h2>
                            {currentSlide === 1 ?
                                <p className="main__subtitle onboarding__content">
                                    {i18n.get('app.page.onboarding.regenerate')} <RepeatIcon nameOfClass="onboarding__icon-pen" /> {i18n.get('app.page.onboarding.orChange')} <PenIcon nameOfClass="onboarding__icon-pen" />.
                                    <br/>
                                    {i18n.get('app.page.onboarding.nutritionReport')}.
                                </p>
                                :
                                <p className="main__subtitle onboarding__content">
                                    {i18n.get('app.page.onboarding.eatingHabits')}
                                    <br/>
                                    {i18n.get('app.page.onboarding.chapcheCreate')}.
                                </p>}
                        </div>
                        <nav className="onboarding__navigation">
                            {width > MOBILE_WIDTH ?
                                <button className={`block__button button button_outline onboarding__navigation-button ${currentSlide > 1 ? '' : 'onboarding__navigation-button_opacity'}`}
                                    onClick={() => setCurrentSlide(1)} disabled={currentSlide === 1}>
                                    {i18n.get(`app.page.onboarding.${ONBOARDING_CONTENT[currentSlide - 1].buttons[0].content}`)}
                                </button>
                                :
                                null}
                            <div className="onboarding__navigation-dots">
                                <span onClick={() => setCurrentSlide(1)}
                                    className={`onboarding__navigation-dot ${currentSlide === 1 ? 'onboarding__navigation-dot_active' : ''}`}></span>
                                <span onClick={() => setCurrentSlide(2)}
                                    className={`onboarding__navigation-dot ${currentSlide === 2 ? 'onboarding__navigation-dot_active' : ''}`}></span>
                            </div>
                            <button className="block__button button onboarding__navigation-button" onClick={() => currentSlide > 1 ? handleCloseUnboarding() : setCurrentSlide(2)}>
                                {i18n.get(`app.page.onboarding.${ONBOARDING_CONTENT[currentSlide - 1].buttons[1].content}`)}
                            </button>
                        </nav>
                        <CloseIcon nameOfClass="onboarding__icon-close" handleClick={handleCloseUnboarding} value={null}/>
                    </article>
                </>}
        </section>
    );
};

export default Onboarding;
