import React, { useState, useEffect, useRef } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimension';

const COUNT_INGREDIENT_LENGTH = 35;

export const checkIsVisible = (element, container) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    let result = true;
    if(elementRect.x >= containerRect.x
        && elementRect.x + elementRect.width <= containerRect.x + containerRect.width - COUNT_INGREDIENT_LENGTH) {
        result = false;
    }
    return result;
};

const IngredientListItem = ({ ingredientTitle }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const ref = useRef();
    const width = useWindowDimensions();
    useEffect(() => {
        setIsOverflowed(checkIsVisible(ref.current, ref.current.parentElement));
    }, [width]);

    return (
        isOverflowed ? 
            null
            :
            <li className="block__content plan__ingredients-item" ref={ref}>
                {ingredientTitle}
            </li>
    );
};

export default IngredientListItem;
