import { 
    FREQUENCY_PERIOD_DAY_AFTER_DAY, 
    FREQUENCY_PERIOD_EVERY_DAY, 
    FREQUENCY_PERIOD_NO_MATTER, 
    FREQUENCY_PERIOD_ONCE_A_PERIOD, 
    FREQUENCY_PERIOD_ONCE_IN_DAY, 
    FREQUENCY_PERIOD_ONCE_IN_FIVE_DAYS, 
    FREQUENCY_PERIOD_ONCE_IN_FOUR_DAYS, 
    FREQUENCY_PERIOD_ONCE_IN_TREE_DAYS, 
    FREQUENCY_PERIOD_ONCE_IN_TWO_DAYS, 
    FREQUENCY_PERIOD_ONCE_IN_WEEK, 
    PREFERENCE_TYPE_CEREAL_MIX, 
    PREFERENCE_TYPE_PRODUCT, 
    PREFERENCE_TYPE_RECIPE, 
    PRODUCT_FORM_GRAIN, 
    errorCallback, 
    makeUrl, 
    successCallbackEmpty,
} from "../constant";
import { i18n } from "../i18n";
import { GET_USER_REQUEST, getFetchRequest, PHRASE_URL_PART, postFetchRequest, PREFERENCE_URL_PART, PRODUCT_URL, SCHEME_URL_PART } from "../store/requests";
import { userService } from "./userService";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";

export const createPossibleHowOftenValues = () => ([
    {
        id: FREQUENCY_PERIOD_NO_MATTER,
        name: i18n.get('preference.scheme.frequency.no-matter'),
        preferenceFrequency: FREQUENCY_PERIOD_NO_MATTER,
        frequencyPeriod: '0',
    },
    {
        id: FREQUENCY_PERIOD_EVERY_DAY,
        name: i18n.get('preference.scheme.frequency.everyDay'),
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '1',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_WEEK,
        name: `${i18n.get('preference.scheme.frequency.onceInWeek')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '7',
    },
    {
        id: FREQUENCY_PERIOD_DAY_AFTER_DAY,
        name: i18n.get('preference.scheme.frequency.dayAfterDay'),
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '2',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_DAY,
        name: `${i18n.get('preference.scheme.frequency.onceInDay')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '1',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_TWO_DAYS,
        name: `${i18n.get('preference.scheme.frequency.onceInTwoDays')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '2',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_TREE_DAYS,
        name: `${i18n.get('preference.scheme.frequency.onceInThreeDays')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '3',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_FOUR_DAYS,
        name: `${i18n.get('preference.scheme.frequency.onceInFourDays')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '4',
    },
    {
        id: FREQUENCY_PERIOD_ONCE_IN_FIVE_DAYS,
        name: `${i18n.get('preference.scheme.frequency.onceInFiveDays')}`,
        preferenceFrequency: FREQUENCY_PERIOD_ONCE_A_PERIOD,
        frequencyPeriod: '5',
    },
]);

const isGrain = (product) => product.productForms.findIndex(it => it.formGroup === PRODUCT_FORM_GRAIN) > -1;

const prepareProductsListForCerealMix = (productsList) => {
    return productsList.filter(it => isGrain(it));
};

export const createPossibleItemCodeValues = (productsList, preferenceType) => {
    if(preferenceType === PREFERENCE_TYPE_PRODUCT) {
        return productsList;
    } else if (preferenceType === PREFERENCE_TYPE_CEREAL_MIX) {
        return prepareProductsListForCerealMix(productsList);
    } else if(preferenceType === PREFERENCE_TYPE_RECIPE) {
        return [
            {
                id: 'snack',
                name: i18n.get('recipe.item.type.snack'),
            },
            {
                id: 'kasha',
                name: i18n.get('recipe.item.type.kasha'),
            },
            {
                id: 'bowl',
                name: i18n.get('recipe.item.type.bowl'),
            },
            {
                id: 'soup',
                name: i18n.get('recipe.item.type.soup'),
            },
            {
                id: 'bread',
                name: i18n.get('recipe.item.type.bread'),
            },
            {
                id: 'smoothies',
                name: i18n.get('recipe.item.type.smoothies'),
            },
            {
                id: 'granola',
                name: i18n.get('recipe.item.type.granola'),
            },
        ];
    } else {
        return [];
    }
};

const createProductsList = (productsList) => {
    let result = [];
    productsList.forEach(it => result.push({id: it.productCode, name: i18n.get(`product.item.code.${it.productCode}`, i18n.APP_LOCALE), 
        code: it.productCode, productForms: it.productForms}));
    return result;
};

export const findHowOftenValue = (preferenceFrequency, frequencyPeriod) => {
    return createPossibleHowOftenValues().find(it => +it.frequencyPeriod === +frequencyPeriod && it.preferenceFrequency === preferenceFrequency);
};

export const checkAllFieldsIsFilled = (preference) => {
    return preference.when && 
        preference.include !== undefined && 
        preference.preferenceType && 
        preference.preferenceFrequency && 
        preference.preferenceItemCodes &&
        preference.preferenceItemCodes.length;
};

export const preferenceSaveService = {
    currentPreference: {},
    preferenceUpdateObservers: [],
    lastPreferenceUpdateTime: null,
    preferenceId: '',
    productsList: [],
    searchValue: '',
    loadPreference(callback) {
        try {
            getFetchRequest(makeUrl([PRODUCT_URL, '?withForms=true']), (response) => {
                this.productsList = createProductsList(response);
            }, errorCallback);
            if(this.preferenceId) {
                getFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, this.preferenceId, '/']), (response) => {
                    this.currentPreference = {...response, howOften: findHowOftenValue(response.preferenceFrequency, response.frequencyPeriod).id};
                    callback && callback();
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    getProducts() {
        return this.productsList;
    },
    getPreference() {
        return this.currentPreference;
    },
    fillPreferenceType(preferenceType) {
        try {
            this.currentPreference.preferenceType = preferenceType; 
            this.currentPreference.preferenceItemCodes = '';
            this.currentPreference.preferenceItemCodes = null; 
            this.lastPreferenceUpdateTime = Date.now(); 
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    fillPreferenceItemSelectField(preferenceKey, value) {
        try {
            this.currentPreference[preferenceKey] = value;  
            this.lastPreferenceUpdateTime = Date.now(); 
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    fillPreferenceItemCerealMix(value, isMix) {
        try {
            if(value) {
                if(!isMix || !this.currentPreference.preferenceItemCodes) {
                    this.currentPreference.preferenceItemCodes = []; 
                }
                value.split(',').forEach(it => this.currentPreference.preferenceItemCodes.push({itemCode: it}));
                this.lastPreferenceUpdateTime = Date.now(); 
            } else {
                this.currentPreference.preferenceItemCodes = []; 
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    fillPreferenceHowOftenField(howOftenKey) {
        const currentPeriod = this.getPossibleHowOftenValues().find(it => it.id === howOftenKey);
        this.currentPreference.howOften = howOftenKey;
        this.currentPreference.preferenceFrequency = currentPeriod.preferenceFrequency;
        this.currentPreference.frequencyPeriod = currentPeriod.frequencyPeriod;
        this.notifyPreferenceUpdateObservers();
    },
    savePreference(callback, withPhrase) {
        try {
            const preferenceId = this.currentPreference.id;
            const preparedBody = {
                when: this.currentPreference.when,
                preferenceFrequency: this.currentPreference.preferenceFrequency,
                frequencyPeriod: this.currentPreference.frequencyPeriod,
                include: this.currentPreference.include,
                preferenceType: this.currentPreference.preferenceType,
                preferenceItemCodes: this.currentPreference.preferenceItemCodes,
                isInternal: false,
                isPermanent: true,
                activityPeriods: [],
            };
            if(withPhrase) {
                preparedBody.phrase = this.currentPreference.phrase;
                preparedBody.locale = i18n.APP_LOCALE;
            }
            if(preferenceId) {
                postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, preferenceId, '/']), 
                    preparedBody, () => callback && callback(), errorCallback, true);
                
            }
        } catch(err) {
            console.log(err);
        }
    },
    getPossibleHowOftenValues(getAllValues) {
        if(this.searchValue && !getAllValues) {
            return createPossibleHowOftenValues().filter(it => it.name.toLowerCase().includes(this.searchValue.toLowerCase()));
        }
        return getAllValues ? createPossibleHowOftenValues() : createPossibleHowOftenValues().slice(0, 3);
    },
    updatePreferenceStructureCode(itemCode) {
        this.itemCode = itemCode;
        this.notifyPreferenceUpdateObservers();
    },
    setHowOftenSearchValue(searchValue) {
        this.searchValue = searchValue;
        this.notifyPreferenceUpdateObservers();
    },
    getSearchValue() {
        return this.searchValue;
    },
    getPossibleCodeValues(preferenceType = null) {
        if(this.itemCode) {
            return createPossibleItemCodeValues(this.productsList, this.currentPreference.preferenceType).filter(it => it.name.toLowerCase().includes(this.itemCode.toLowerCase()));
        }
        return createPossibleItemCodeValues(this.productsList, this.currentPreference.preferenceType ? this.currentPreference.preferenceType : preferenceType);
    },
    registerItemUpdateObserver(observer) {
        this.preferenceUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.preferenceUpdateObservers = this.preferenceUpdateObservers.filter(preferenceUpdateFunction => preferenceUpdateFunction !== observer);
    },
    notifyPreferenceUpdateObservers() {
        this.preferenceUpdateObservers.forEach(preferenceUpdateObserver => preferenceUpdateObserver());
    },
    getDateLastUpdate() {
        return this.lastFormUpdateTime;
    },
    clearService() {
        this.currentPreference = {};
        this.preferenceId = '';
    },
    changePreferenceItemPhrase(phraseValue) {
        this.currentPreference.phrase = phraseValue;
        updateValueSevice.updateItemValue(() => {
            this.savePreference(null, true);
        });
    },
    generateTableByPhrase () {
        updateValueSevice.updateItemValue(() => {
            if(!this.preferenceId) {
                postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART]), 
                    {phrase: this.currentPreference.phrase, locale: i18n.APP_LOCALE}, (response) => {
                        this.currentPreference.id = response[0].id;
                        this.preferenceId = response[0].id;
                        postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, this.preferenceId, SCHEME_URL_PART]), 
                            null, successCallbackEmpty, errorCallback, true);
                    }, errorCallback);
            } else {
                postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, this.preferenceId, SCHEME_URL_PART]), 
                    null, successCallbackEmpty, errorCallback, true);
            }
        });
    },
    generatePhraseByTable() {
        const preparedBody = {
            when: this.currentPreference.when,
            preferenceFrequency: this.currentPreference.preferenceFrequency,
            frequencyPeriod: this.currentPreference.frequencyPeriod,
            include: this.currentPreference.include,
            preferenceType: this.currentPreference.preferenceType,
            preferenceItemCodes: this.currentPreference.preferenceItemCodes,
            isInternal: false,
            isPermanent: true,
            activityPeriods: [],
        };
        if(!this.preferenceId) {
            postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART]), 
                preparedBody, (response) => {
                    this.currentPreference.id = response[0].id;
                    this.preferenceId = response[0].id;
                    postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, this.preferenceId, PHRASE_URL_PART]), 
                        null, successCallbackEmpty, errorCallback, true);
                }, errorCallback);
        } else {
            postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, this.preferenceId, PHRASE_URL_PART]), 
                null, successCallbackEmpty, errorCallback, true);
        }
    },
};

export const initializePreferenceSaveService = (preferenceId) => {
    preferenceSaveService.preferenceId = preferenceId;
    preferenceSaveService.loadPreference();
};
