import { GET_CURRENT_USER_REQUEST, getFetchRequest } from "./store/requests";

export const CHAPCHE_API_URL = 'BACKEND_API_URL';

export const successCallbackEmpty = () => {
};

export const errorCallback = (error) => {
    console.log(error);
    getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), successCallbackEmpty, () => {
        window.location.href = '/login';
    });
};

export const makeUrl = (params) => {
    const result = `${CHAPCHE_API_URL}` + params.join('');
    return result;
};

export const MANAGER_ROLE = 'manager';
export const ADMIN_ROLE = 'admin';
export const CUSTOMER_ROLE = 'customer';

export const PAGE_TAB_NAME_LIST = 'list';
export const PAGE_TAB_NAME_NEW = 'new';
export const ITEM_TAB_NAME = 'item';

export const ACTIVE_FORM_LOGIN = 'login';
export const ACTIVE_FORM_SIGN_UP = 'signup';
export const ACTIVE_FORM_SEND_CODE = 'code';
export const ERROR_WRONG_CODE_TYPE = 'code';
export const ERROR_WRONG_PHONE_TYPE = 'phone';

export const MOUSE_DOWN_EVENT = 'mousedown';
export const KEY_UP_EVENT = 'keyup';
export const CHANGE_VISIBILITY_EVENT = 'visibilitychange';
export const VISIBILITY_STATE_VISIBLE = 'visible';

export const ACTION_NEED_TO_SHOW_ON_BOARDING = 'on-boarding';

export const ONE_DAY = 24 * 60 * 60 * 1000;
export const WEEK_DAYS_COUNT = 7; 

export const PLAN_TAB = 'plan';
export const PREFERENCE_TAB = 'preference';
export const PROFILE_TAB = 'profile';
export const PREFERENCE_PHRASE_TAB = 'phrase';

export const LOCALE_EN = 'en';
export const LOCALE_RU = 'ru';

export const TABLET_WIDTH = 1024;
export const MOBILE_WIDTH = 768;

export const COMMIT_FRONTEND = 'LAST_COMMIT_FRONTEND';
export const COMMIT_BACKEND = 'LAST_COMMIT_BACKEND';

export const FREQUENCY_PERIOD_NO_MATTER = 'no-matter';
export const FREQUENCY_PERIOD_ONCE_A_PERIOD = 'once-a-period';
export const FREQUENCY_PERIOD_EVERY_DAY = 'everyDay';
export const FREQUENCY_PERIOD_DAY_AFTER_DAY = 'dayAfterDay';
export const FREQUENCY_PERIOD_ONCE_IN_DAYS = 'onceInDays';
export const FREQUENCY_PERIOD_ONCE_IN_WEEK = 'onceInWeek';
export const FREQUENCY_PERIOD_ONCE_IN_DAY = 'onceInDay';
export const FREQUENCY_PERIOD_ONCE_IN_TWO_DAYS = 'onceInTwoDays';
export const FREQUENCY_PERIOD_ONCE_IN_TREE_DAYS = 'onceInThreeDays';
export const FREQUENCY_PERIOD_ONCE_IN_FOUR_DAYS = 'onceInFourDays';
export const FREQUENCY_PERIOD_ONCE_IN_FIVE_DAYS = 'onceInFiveDays';

export const PREFERENCE_TYPE_PRODUCT = 'product';
export const PREFERENCE_TYPE_RECIPE = 'recipe';
export const PREFERENCE_TYPE_PROPERTY = 'property';
export const PREFERENCE_TYPE_CEREAL_MIX = 'cereal-mix';

export const CHAPCHE_WEBSOCKET_URL = 'WEBSOCKET_API_URL';

export const POPUP_KEY_DELETE_USER_PREFERENCE = 'delete-user-preference';
export const POPUP_KEY_PREFERENCES_FILTER = 'preferences-filter';

export const DIGITS_REG_EXP = /^\d+$/;
export const NON_DIGITS_REG_EXP = /\D/g;

export const PRODUCT_FORM_GRAIN = 'grain';

export const NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED = 'preference-phrase-updated-success';
export const NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATED = 'preference-updated-success';
export const NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL = 'preference-phrase-updated-fail';
export const NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL = 'preference-updated-fail';

export const DELETE_PREFERENCE_COUNT = 5;

export const MODAL_SIZE_LARGE = 'large';
