import React, { useState, useEffect } from 'react';
import { ErrorHandler } from './ErrorHandler';
import { GET_CURRENT_USER_REQUEST, GET_USER_REQUEST, getFetchRequest, postFetchRequest } from '../store/requests';
import { ACTIVE_FORM_SEND_CODE, ERROR_WRONG_CODE_TYPE, ERROR_WRONG_PHONE_TYPE, LOCALE_EN, LOCALE_RU, TABLET_WIDTH, errorCallback, makeUrl, successCallbackEmpty } from '../constant';
import { i18n } from '../i18n';
import Modal from './Modal';
import { MODAL_SMALL_SIZE } from './helper';
import { DangerIcon } from '../appearence/icons/danger_icon';
import { FillCodeLayout } from './LoginPage';
import useWindowDimensions from '../hooks/useWindowDimension';
import LocaleSwitcher from './sharedComponents/LocaleSwitcher';
import Select from './sharedComponents/Select';
import PhoneInput from './sharedComponents/PhoneInput';
import ThemeSwitcher from './ThemeSwitcher';



const EDIT_PHONE_OBJECT = {
    name: 'phone',
    fields: [
        {
            name: i18n.get('app.page.profile.phone'),
            value: '',
        },
    ],
};

const ChangeModalContent = ({ phone, setPhoneInput, handleClose, saveChanges, error }) => {

    return (
        <>
            <h2 className="modal__title block__title-h3">{i18n.get(`app.page.profile.modalCabinet.title`)}</h2>
            <p className="block__content modal__text profile__modal-text">{i18n.get(`app.page.profile.modalCabinet.content`)}</p>
            <PhoneInput phone={phone} setPhoneInput={setPhoneInput} error={error} />
            {error.type === 'phone' ? <p className="block__bullet alert profile__alert">{error.message}</p> : ''}
            <div className="block__button modal__button-group">
                <button className="block__button button button_outline" onClick={() => handleClose(null)}>{i18n.get(`app.page.profile.modalCabinet.cancel`)}</button>
                <button className="block__button button" onClick={saveChanges}>{i18n.get(`app.page.profile.modalCabinet.change`)}</button>
            </div>
        </>
    );
};

const formatPhoneNumber = (phone) => {
    const cleanedString = ('' + phone).replace(/\D/g, '');
    const regexMatchString = cleanedString.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (regexMatchString) {
        return ['(', regexMatchString[1], ') ', regexMatchString[2], ' ', regexMatchString[3], ' ', regexMatchString[4]].join('');
    }
    return null;
};

const ProfilePage = () => {
    const [profileData, setProfileData] = useState({ name: '', email: '', isChanged: false });
    const [activeModalItem, setActiveModalItem] = useState(null);
    const [error, setError] = useState({ type: '', message: '' });
    const [code, setCode] = useState('');
    const [counter, setCounter] = useState(60);
    const [codeIsSended, setCodeIsSended] = useState(false);
    const [phone, setPhone] = useState('');
    const [mobileLocaleList, setMobileLocaleList] = useState({
        activeList: [],
        fullList: [{ name: 'Русский', id: LOCALE_RU, code: LOCALE_RU }, { name: 'English', id: LOCALE_EN, code: LOCALE_EN }],
    });
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (codeIsSended) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, codeIsSended]);

    const handleChangeProfileData = (e) => {
        profileData[e.target.name] = e.target.value;
        setProfileData({ ...profileData, isChanged: true });
    };

    useEffect(() => {
        getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]),
            (response) => setProfileData({ ...response, phone: response.phone ? response.phone.substring(2) : '' }), errorCallback);
    }, []);

    const handleChangeUser = (e) => {
        profileData[e.target.name] = e.target.value;
        setProfileData({ ...profileData });
        const data = {
            name: profileData.name,
            appRoleId: profileData.appRole.id,
        };
        postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/']), data, () => { }, errorCallback, true);
    };

    const handleChangeProfile = (e) => {
        setError({ type: '', message: '' });
        activeModalItem[e.target.name] = e.target.value;
        setActiveModalItem({ ...activeModalItem });
    };

    const handleChangePhone = (e) => {
        setError({ message: '', type: '' });
        setCode(e.target.value);
        if (phone && e.target.value.trim().length === 6) {
            const data = { phone: '+7' + phone, code: e.target.value };
            postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/phone/confirm/']), data,
                () => setProfileData({ ...profileData, phone }), errorCallback, true);
        } else {
            setError({ type: ERROR_WRONG_CODE_TYPE, message: 'Введен неверный код' });
        }
    };

    const successUpdatePhoneCallback = (response) => {
        if (response.ok) {
            setActiveModalItem(ACTIVE_FORM_SEND_CODE);
            setCodeIsSended(true);
        }
    };

    const saveChanges = () => {
        const data = {
            phone,
        };
        postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/phone/']), data, successUpdatePhoneCallback, errorCallback, true);
    };

    const handleUpdateLocale = (newLocale) => {
        setProfileData({ ...profileData, locale: newLocale });
        const data = { locale: newLocale };
        i18n.APP_LOCALE = newLocale;
        i18n.create({ [newLocale]: i18n[newLocale] }, newLocale);
        postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/locale/']), data, successCallbackEmpty, errorCallback, true);
    };

    return (
        <section className="workspace workspace__profile">
            {activeModalItem ?
                <Modal content={activeModalItem === ACTIVE_FORM_SEND_CODE ?
                    <FillCodeLayout width={width} code={code} counter={counter} setCode={setCode} setCodeIsSended={setCodeIsSended} error={error}
                        handleSendCode={saveChanges} handleLogin={handleChangePhone} />
                    :
                    <ChangeModalContent handleChange={handleChangeProfile} saveChanges={saveChanges} handleClose={setActiveModalItem}
                        error={error} phone={phone} setPhoneInput={setPhone} />} handleCloseModal={setActiveModalItem}
                size={MODAL_SMALL_SIZE} />
                :
                ''}
            <h1 className="profile__title block__title-h2">
                {i18n.get('app.page.profile.title')}
                {profileData.id && width > TABLET_WIDTH ?
                    <div className="locale__select locale__select-profile">
                        <ThemeSwitcher />
                        <LocaleSwitcher localeCode={profileData.locale} notifyListener={handleUpdateLocale} />
                    </div>
                    :
                    null}
            </h1>
            <div className="profile__wrapper">
                <article className="profile__article">
                    <h2 className="profile__subtitle block__title-h3">{i18n.get('app.page.profile.myData')}</h2>
                    <form className="profile__form">
                        <label className="block__text profile__label">{i18n.get('app.page.profile.name')}
                            <input className="profile__input"
                                value={profileData.name ? profileData.name : ''}
                                name="name"
                                onChange={handleChangeProfileData} />
                        </label>
                        <PhoneInput phone={formatPhoneNumber(profileData.phone)} setPhoneInput={null} error={{}}  />
                        <p className="button_size block__bullet profile__change" onClick={() => setActiveModalItem(EDIT_PHONE_OBJECT)}>{i18n.get('app.page.profile.changePhone')}</p>
                        {width <= TABLET_WIDTH ?
                            <Select titleValue={profileData.locale === 'ru' ? 'Русский' : 'English'} handleChangeValue={handleUpdateLocale} activeOptionList={[]}
                                listOfOptions={mobileLocaleList} setListOfOptions={setMobileLocaleList} />
                            :
                            null}
                        <button type="button" className={`button_size block__button button profile__button ${profileData.isChanged ? '' : 'button_disabled'}`}
                            onClick={(e) => profileData.isChanged ? handleChangeUser(e) : null}>
                            <span className="button__content">{i18n.get('app.page.product.saveChangesSmall')}</span>
                        </button>
                    </form>
                </article>
                {width < TABLET_WIDTH ?
                    null
                    :
                    <article className="profile__article profile__article-history">
                        <h2 className="profile__subtitle block__title-h3">{i18n.get('app.page.profile.history')}</h2>
                        <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')} />
                    </article>}
            </div>
            {width < TABLET_WIDTH ?
                null
                :
                <article className="profile__article profile__article_full-width">
                    <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')} />
                </article>}
        </section>
    );
};

export default ProfilePage;
