import { makeUrl } from "../constant";
import { GET_CURRENT_USER_REQUEST, getFetchRequest } from "../store/requests";

export const userService = {
    currentUser: {},
    loadUser(successCallback, errorCallback) {
        getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (response) => {
            this.currentUser = response;
            successCallback(response);
        }, errorCallback);
    },
    getUser() {
        return this.currentUser;
    },
};
